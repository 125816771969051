<template>
  <div class="error_tips">
    <img src="@/assets/warning.png" alt="warning" class="svg" />
    <div class="tips">
      请在电脑PC端使用Chrome（谷歌浏览器）<br />
      或Edge浏览器最新版本进行访问
    </div>
  </div>
</template>

<style lang="less" scoped>
.error_tips {
  width: 100vw;
  height: 100vh;
  background: url('@/assets/bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20% 33px 0 35px;
  box-sizing: border-box;

  .svg {
    width: 25.6%;
    height: fit-content;
  }

  .tips {
    height: fit-content;
    font-size: 16px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.898);
    text-align: center;
    margin-top: 24px;
  }
}
</style>

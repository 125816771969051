<template>
  <div class="mastery">
    <div class="data">
      <a-table
        :columns="columns"
        :dataSource="data"
        :pagination="pagination"
        bordered
      >
        <template #bodyCell="{ column, record }">
          <span
            :style="{ color: getColor(record.mastery) }"
            v-if="column.key === 'mastery'"
          >
            {{ record.mastery }}%
          </span>
          <a v-if="column.key === 'brush'" @click="aiPractice(record)"
            >进入刷题
          </a>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script setup>
import { useCommonStore, useUserStore } from '@/store';
import { ref, reactive, getCurrentInstance, watchEffect, computed } from 'vue';
import { getColor } from '@/utils';

const { proxy } = getCurrentInstance(),
  userStore = useUserStore(),
  commonStore = useCommonStore();

const userInfo = computed(() => {
  return userStore.userInfo;
});
const props = defineProps({
  // 数据源
  data: { type: Array },
  // 表头
  columns: { type: Array },
  // 总计
  total: { type: Number || String },
  // 当前页
  current: { type: Number || String },
  // 分页器
  pagination: { type: Boolean, default: true },
});

const emits = defineEmits(['onChange']);
const pagination = ref({
    total: 0,
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    showQuickJumper: true,
    hideOnSinglePage: false,
    pageSizeOptions: [5, 10, 20, 50],
    showTotal: (total) => `共 ${total} 项数据`,
    onChange: (page, pageSize) => handlePageChange(page, pageSize),
    onShowSizeChange: (current, size) => showSizeChange(current, size),
  }),
  // 翻页
  handlePageChange = (page, pageSize) => {
    pagination.value.current = page;
    pagination.value.pageSize = pageSize;
    emits('onChange', pagination.value);
  },
  // 更换每页显示
  showSizeChange = (current, size) => {
    pagination.value.current = current;
    pagination.value.pageSize = size;
    // emits('onChange', pagination.value);
  };

const aiPractice = (record) => {
  const { ccId, skId } = record;
  console.log(record);
  const params = { ccId, skId, stuId: userInfo.value?.stuId };

  proxy.$api.generatePracticePaper(params).then((res) => {
    if (res) {
      if (res.data == -1)
        return proxy.$message.info('该知识点的智能选题ai暂未部署，请等候更新');
      proxy.$router.push({ name: 'practice', query: { paperId: res.data } });
    }
  });
};

watchEffect(() => {
  if (!props.pagination) {
    pagination.value = false;
    return;
  }
  pagination.value.total = props.total;
  pagination.value.current = props.current;
});
</script>

<style lang="less" scoped>
.mastery {
  width: 100%;
  height: fit-content;

  .master {
    width: 100%;
    height: 48px;
    background: #f9f6d0;
    border-radius: 8px;
    margin-top: 8px;
    padding: 10px 0 10px 24px;
    box-sizing: border-box;
    font-size: 16px;
    color: #be5a00;
    line-height: 28px;
  }

  :deep(.data) {
    width: 100%;
    height: fit-content;
    margin-top: 24px;

    .ant-table-cell {
      font-size: 18px;
    }
  }
}
</style>

<template>
  <div class="gauge_chart" ref="gaugeChartRef"></div>
</template>

<script setup>
import { useCommonStore } from '@/store';
import { getColor } from '@/utils';
import {
  ref,
  reactive,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  watchEffect,
} from 'vue';
import * as echarts from 'echarts';

const { proxy } = getCurrentInstance(),
  commonStore = useCommonStore();

onMounted(() => {
  // 监听窗口大小变化
  window.addEventListener('resize', resize);
});

onUnmounted(() => {
  // 清除监听
  window.removeEventListener('resize', resize);
});

const emits = defineEmits(['onFinished']),
  props = defineProps({
    // 展示的数据内容
    data: { type: Number },
    // pdf/普通html
    type: { type: String },
    // 数据状态（获取后端数据是否完成）
    status: { type: Boolean, default: false },
    // course/class
    reportType: { type: String, default: 'course' },
    // 是否为竞赛类型（刷题练习）
    isContest: { type: Boolean, default: false },
  });

let gaugeChart;
const gaugeChartRef = ref(null),
  // 初始化仪表盘
  initGaugeChart = () => {
    const innerWidth = window.innerWidth;
    const { colorMap } = commonStore;
    // 仪表盘dom
    let chartDom = gaugeChartRef.value;
    // 仪表盘配置
    gaugeChart = chartDom && echarts.init(chartDom);

    let myColor,
      myName,
      myData = props.data;
    // 仪表盘标题
    if (props.type == 'pdf' && props.reportType == 'course')
      myName = `${props.isContest ? '' : '课程'}知识点掌握度`;
    else myName = '整体知识点掌握度';
    // 仪表盘配置项
    const option = {
      // 数据项
      series: [
        // 内环
        {
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          center: ['50%', '60%'],
          radius: '77%',
          min: 0,
          max: 100,
          splitNumber: 5,
          // 根据数据范围填充颜色
          itemStyle: { color: (params) => colorMap[getColor(params.value)] },
          // 轴线
          axisLine: {
            lineStyle: { width: 25, color: [[1, colorMap['grey']]] },
          },
          // 进度样式
          progress: { show: true, width: 25 },
          // 指针
          pointer: { show: false },
          // 分隔线
          splitLine: { show: false },
          // 刻度样式
          axisTick: { show: false },
          // 刻度标签
          axisLabel: {
            color: 'rgba(0,0,0,0.6)',
            fontSize: 20,
            distance: -35,
            rotate: 'tangential',
          },
          // 标题
          title: {
            offsetCenter: [0, '-10%'],
            fontSize: 18,
          },
          // 最里面详情，用于显示数据
          detail: {
            fontSize: 30,
            offsetCenter: [0, '-35%'],
            valueAnimation: true,
            color: 'inherit',
            formatter: (value) => {
              return value + '%';
            },
          },
          data: [{ value: myData, name: myName }],
        },
        // 外环
        {
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          center: ['50%', '60%'],
          radius: '77%',
          min: 0,
          max: 100,
          splitNumber: 5,
          // 轴线
          axisLine: {
            lineStyle: {
              width: 2,
              color: [
                [0.6, colorMap['red']],
                [0.8, colorMap['blue']],
                [1, colorMap['green']],
              ],
            },
          },
          // 指针
          pointer: { show: false },
          // 分隔线
          splitLine: { show: false },
          // 刻度样式
          axisTick: { show: false },
          // 刻度标签
          axisLabel: { show: false },
          // 标题
          title: { show: false },
          // 详情，用于显示数据
          detail: { show: false },
        },
      ],
    };

    chartDom && option && gaugeChart.setOption(option);
    // 监听仪表盘的渲染进度
    gaugeChart.on('finished', (params) => {
      emits('onFinished', 'gauge');
    });
  },
  resize = () => {
    gaugeChart?.resize();
  };

// defineExpose({ initGaugeChart });

watchEffect(() => {
  if (props.status) initGaugeChart();
});
</script>

<style lang="less" scoped>
.gauge_chart {
  width: 100%;
  height: 100%;
}
</style>

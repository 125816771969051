<template>
  <div class="bar_chart" ref="barChartRef"></div>
</template>

<script setup>
import { useCommonStore } from '@/store';
import { getColor } from '@/utils';
import {
  ref,
  reactive,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  watchEffect,
} from 'vue';
import * as echarts from 'echarts';

const { proxy } = getCurrentInstance(),
  commonStore = useCommonStore();

onMounted(() => {
  // 监听窗口大小变化
  window.addEventListener('resize', resize);
});

onUnmounted(() => {
  // 清除监听
  window.removeEventListener('resize', resize);
});

const emits = defineEmits(['onClick', 'onFinished']),
  props = defineProps({
    // 展示的数据内容
    data: { type: Array },
    // pdf/普通html
    type: { type: String },
    // 数据状态（获取后端数据是否完成）
    status: { type: Boolean, default: false },
    // course/class
    reportType: { type: String, default: 'course' },
    // 是否为竞赛类型（刷题练习）
    isContest: { type: Boolean, default: false },
  });

let barChart;
const barChartRef = ref(null),
  // 初始化柱状图
  initBarChart = () => {
    const innerWidth = window.innerWidth;
    const { colorMap } = commonStore;
    // 柱状图dom
    let chartDom = barChartRef.value,
      // 根据屏幕宽度设置柱形初始宽度
      barWidth = innerWidth < 1440 ? 50 : 70;
    // 柱状图配置
    barChart = chartDom && echarts.init(chartDom);
    // x轴数据项
    const xItems = [],
      // y轴数据项
      yItems = [];
    // 遍历传递进来的data数据来构造柱状图的数据
    props?.data?.forEach((item) => {
      const {
        name,
        skName,
        courseName,
        knowledge,
        courseId,
        id,
        mastery,
        progress,
      } = item;

      let arr =
        knowledge ||
        courseName?.split('') ||
        skName?.split('') ||
        name?.split('');
      // 屏幕宽度＜1440且超过9个字截取，展示8个字符
      if (arr.length > 9 && innerWidth < 1440) {
        arr.forEach((ch, index) => {
          if (index > 8) arr[index] = '';
        });
        arr = arr.join('');
        arr += '...';
      } else arr = knowledge || courseName || skName || name;

      // courseName：展示的粗体文字；progress：学习进度；courseId：用户点击进入课程分析后根据id去获取课程内容
      xItems.push(
        JSON.stringify({ courseName: arr, progress, courseId: courseId || id })
      );
      // y轴代表掌握度
      yItems.push(mastery);
    });

    let title = {};
    // 课程pdf的标题配置
    if (props.type == 'pdf' && props.reportType == 'course')
      title = { fontSize: 16, text: `知识点掌握度 Top ${props.data.length}` };
    // 课程/赛事html的标题配置
    else
      title = {
        fontSize: 16,
        text: props.isContest
          ? '刷题练习分析'
          : `课程学习 Top ${props.data.length}`,
        subtext: '知识点掌握度',
      };
    // 柱状图配置项
    const option = {
      // 标题
      title: title,
      // 坐标
      grid: {
        left: 0,
        right: 32,
        bottom: 0,
        containLabel: true,
      },
      // 提示框
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow' },
        formatter: function (value, index) {
          return value;
        },
      },
      // 柱上方文本
      label: {
        show: true,
        position: 'top',
        formatter: function (params) {
          return params.value + '%';
        },
      },
      // x轴内容
      xAxis: {
        type: 'category',
        data: [...xItems],
        triggerEvent: true,
        axisLabel: {
          interval: 0,
          formatter: function (value, index) {
            const obj = JSON.parse(value);
            const { courseName, progress } = obj;
            // x轴文本
            let axis = `{courseName|${courseName}}\n\n`;
            if (props.type == 'pdf' && props.reportType == 'class')
              axis += `{progress|学习进度 ${progress}}`;
            if (props.type != 'pdf')
              axis += `{progress|学习进度 ${progress}}\n\n{btn|进入课程分析}{hiddenText|courseId}\n`;
            return axis;
          },
          // 富文本配置（对应上方xAxis的formatter的字段）
          rich: {
            courseName: { color: '#000', fontSize: 16, fontWeight: 'bold' },
            progress: { fontSize: 16, color: '#3D3D3D' },
            btn: {
              fontSize: 16,
              color: '#2BA471',
              borderWidth: 1,
              borderColor: '#2BA471',
              borderType: 'solid',
              borderRadius: 100,
              padding: [5, 12, 5, 12],
              height: 22,
            },
            hiddenText: { fontSize: 0 },
          },
        },
      },
      // y轴内容
      yAxis: {
        type: 'value',
        min: 0,
        max: 100,
        axisLabel: {
          formatter: function (value, index) {
            return value + '%';
          },
        },
      },
      // 数据项
      series: [
        {
          type: 'bar',
          data: [...yItems],
          barWidth: barWidth,
          itemStyle: { color: (params) => colorMap[getColor(params.value)] },
          emphasis: { itemStyle: { color: colorMap['orange'] } },
        },
      ],
    };
    // 滚动配置
    const dataZoom = [
      {
        id: 'dataZoomX',
        type: 'slider',
        xAxisIndex: [0],
        filterMode: 'filter',
        height: 5,
        bottom: 0,
        start: 0,
        end: 50,
        moveHandleSize: 2,
        showDetail: false,
      },
      {
        type: 'inside',
        xAxisIndex: [0],
        zoomOnMouseWheel: false, //滚轮不触发缩放
        moveOnMouseMove: true, //鼠标移动触发平移
        moveOnMouseWheel: true, //鼠标滚轮触发平移
      },
    ];

    if (xItems.length > 5) option['dataZoom'] = dataZoom;

    chartDom && option && barChart.setOption(option);

    barChart.off('click');
    // 点击事件监听
    barChart.on('click', (params) => {
      // 点击x轴的button
      if (
        params.componentType == 'xAxis' &&
        params.event.topTarget.style.text == '进入课程分析'
      )
        emits('onClick', params);
    });
    // hover的高亮配置
    barChart.on('mouseover', (params) => {
      if (params.componentType == 'xAxis') {
        barChart.dispatchAction({
          type: 'highlight',
          dataIndex: params.dataIndex,
        });
      }
    });
    barChart.on('mouseout', (params) => {
      barChart.dispatchAction({
        type: 'downplay',
      });
    });
    // 监听柱状图的渲染进度
    barChart.on('finished', (params) => {
      emits('onFinished', 'bar');
    });
  },
  resize = () => {
    barChart?.resize();
  };

// defineExpose({ initBarChart });

watchEffect(() => {
  if (props.status) initBarChart();
});
</script>

<style lang="less" scoped>
.gauge_chart {
  width: 100%;
  height: 100%;
}
</style>

/*
 * @Date: 2022-08-16 09:31:08
 * @LastEditTime: 2024-05-31 17:15:20
 */
import { defineStore } from 'pinia';

const useCommonStore = defineStore('common', {
  state: () => ({
    // 面包屑
    breadcrumb: [],
    // 课程信息
    course: {},
    // 购买loading
    buyLoading: false,
    // 是否注册
    isRegister: false,
    // 机构logo
    companyLogo: null,
    btnValue: 0,
    // 默认昵称
    defaultName: '暂无昵称',
    // 默认头像
    defaultAvatar: 'https://cdn.codedancing.cn/cover/defaultAvatar.png',
    // 颜色字典
    colorMap: {
      red: '#D54941',
      blue: '#0052D9',
      green: '#2BA471',
      orange: '#E37318',
      grey: 'rgba(24,24,24, 0.05)',
    },
    // 数据区间（仪表盘）
    dataBoundary: [60, 80, 100],
  }),

  getters: {
    breadcrumbs: (state) => state.breadcrumb,
    courseInfo: (state) => state.course,
  },
  actions: {
    s_set_breadcrumb(breadcrumb) {
      this.breadcrumb = breadcrumb;
    },
    s_set_course(course) {
      this.course = course;
    },
    s_set_buyLoading(isLoading) {
      this.buyLoading = isLoading;
    },
    s_set_is_register(isRegister) {
      this.isRegister = isRegister;
    },
    s_set_company_logo(companyLogo) {
      this.companyLogo = companyLogo;
    },
    s_set_btn_value(btnValue) {
      this.btnValue = btnValue;
      sessionStorage.setItem('btnValue', btnValue);
    },
  },
});

export default useCommonStore;

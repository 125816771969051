import * as api from '@/api';
import * as router from '@/router';
import { useCommonStore, useUserStore } from '@/store';

function bindFreeCourse(stuId, courseId) {
  console.log(stuId, courseId);
  return new Promise((resolve, reject) => {
    api.default.bindFreeCourse({ stuId, courseId }).then((res) => {
      if (res) resolve(true);
    });
  });
}

export function go2buy(course) {
  console.log(course);
  const commonStore = useCommonStore(),
    userStore = useUserStore();

  const { isUserBuy, ccId, courseId, sellingPrice } = course;

  const stuId = userStore?.userInfo?.stuId;
  if (sellingPrice == 0 && stuId) {
    bindFreeCourse(stuId, courseId).then((res) => {
      if (res)
        router.default.push({
          path: '/classification/content',
          query: { ccId, courseId },
        });
    });
    return;
  }

  commonStore.s_set_buyLoading(true);
  if (isUserBuy) {
    router.default.push({
      path: '/classification/content',
      query: { ccId, courseId },
    });
    commonStore.s_set_buyLoading(false);
  }
}

// 下单
function placeOrder({ courseId, actualTotal }) {
  return new Promise((resolve) => {
    api.default
      .placeOrder({ courseId, actualTotal })
      .then((res) => {
        if (res.resultCode == 200) resolve(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        const commonStore = useCommonStore();
        commonStore.s_set_buyLoading(false);
      });
  });
}

// 支付订单
export function payOrder({ orderId }) {}

<template>
  <div class="overall_capacity">
    <div class="content">
      <!-- 雷达图 -->
      <radar
        class="radar_box"
        :indicator="indicator"
        :dataSource="dataSource"
        :status="isFinished"
        @on-finished="(e) => emits('onFinished', 'radar')"
      />
      <!-- 总体能力展示 -->
      <div class="info">
        <template v-for="(item, index) in indicator" :key="item">
          <div class="info_item">
            <div class="info_item_top">
              <div
                class="info_item_label"
                :style="{ background: bgColor[index] }"
              >
                <img class="item_icon" :src="item.icon" />
                <span class="item_title">{{ item.name }}</span>
              </div>
              <div
                class="info_item_value"
                :style="{ background: valColor[index] }"
              >
                <span>{{ item.data > 0 ? item.data + '分' : '无' }}</span>
              </div>
            </div>
            <div class="info_item_bottom">
              <span class="info_desc">{{ item.desc }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  computed,
  watchEffect,
} from 'vue';
import * as echarts from 'echarts';
import { radar } from '@/components';

const { proxy } = getCurrentInstance();

const props = defineProps({
    // 数据源
    data: { type: Array },
    // 是否完成获取接口数据
    isFinished: { type: Boolean, default: false },
  }),
  emits = defineEmits(['onFinished']);

// 总体能力
let bgColor = [
    '#366EF4',
    '#2BA471',
    '#D54941',
    '#B07AFF',
    '#F8BA0A',
    '#E37318',
  ],
  valColor = ['#D9E1FF', '#C6F3D7', '#FFD8D2', '#E9DDF9', '#FAEECB', '#FFD9C2'],
  iconArr = [
    require('@/assets/personalCenter/item_1.png'),
    require('@/assets/personalCenter/item_2.png'),
    require('@/assets/personalCenter/item_3.png'),
    require('@/assets/personalCenter/item_4.png'),
    require('@/assets/personalCenter/item_5.png'),
    require('@/assets/personalCenter/item_6.png'),
  ],
  // 雷达图指示器
  indicator = ref([]),
  // 雷达图数据源
  dataSource = ref([]),
  // 雷达图配置（返回值与顺序）
  formatter = (value) => {
    const arr = value?.split('_');
    if (arr && arr?.length) {
      const val = arr[0];
      const index = arr[1];
      return { val, index };
    } else return {};
  };
// 初始化
const init = () => {
  let list = [];

  if (props?.data?.length > 0)
    props?.data?.forEach((item, index) => {
      const { name, score, desc } = item;
      list.push({
        desc,
        max: 10,
        data: score,
        icon: iconArr[index],
        color: bgColor[index],
        name: formatter(name)?.val || undefined,
      });
    });

  indicator.value = list;
  dataSource.value = props?.data;
};

watchEffect(() => {
  if (props.isFinished) init();
});
</script>

<style lang="less" scoped>
.overall_capacity {
  .content {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    margin-top: 8px;

    .radar_box {
      width: 412px;
      height: 412px;
    }

    .info {
      width: 0;
      flex: 1;
      height: fit-content;
      margin-left: 24px;
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .info_item {
        width: 50%;
        height: fit-content;
        font-size: 14px;
        color: #3d3d3d;
        line-height: 22px;
        flex-shrink: 0;
        margin-bottom: 32px;
        padding-right: 27px;
        box-sizing: border-box;

        .info_item_top {
          width: 100%;
          height: fit-content;
          display: flex;
          align-items: center;

          .info_item_label {
            min-width: 108px;
            height: 36px;
            border-radius: 4px 0 0 4px;
            padding: 6px 12px 6px 8px;
            box-sizing: border-box;
            display: flex;
            align-items: center;

            .item_icon {
              width: 24px;
              height: 24px;
            }

            .item_title {
              font-size: 14px;
              color: #ffffff;
              line-height: 22px;
              margin-left: 8px;
            }
          }

          .info_item_value {
            width: 70px;
            height: 36px;
            border-radius: 0 4px 4px 0;
            font-size: 16px;
            font-weight: 600;
            color: #3d3d3d;
            line-height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .info_item_bottom {
          width: 100%;
          height: fit-content;
          margin-top: 16px;

          .info_desc {
            width: 100%;
            min-height: 22px;
            font-size: 14px;
            color: #3d3d3d;
            line-height: 22px;
          }
        }
      }
    }
  }
}
</style>

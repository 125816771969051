<template>
  <div class="pie_chart" ref="pieChartRef"></div>
</template>

<script setup>
import { useCommonStore } from '@/store';
import { getColor } from '@/utils';
import {
  ref,
  reactive,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  watchEffect,
} from 'vue';
import * as echarts from 'echarts';

const { proxy } = getCurrentInstance(),
  commonStore = useCommonStore();

onMounted(() => {
  // 监听窗口大小变化
  window.addEventListener('resize', resize);
});

onUnmounted(() => {
  // 清除监听
  window.removeEventListener('resize', resize);
});

const emits = defineEmits(['onClick', 'onFinished']),
  props = defineProps({
    // 展示的数据内容
    data: { type: Object },
    // 数据状态（获取后端数据是否完成）
    status: { type: Boolean, default: false },
  });

// 饼图
let pieChart;
const pieChartRef = ref(null),
  // 初始化饼图
  initPieChart = (data) => {
    const innerWidth = window.innerWidth;
    // 饼图dom
    let chartDom = pieChartRef.value;
    // 饼图配置
    pieChart = chartDom && echarts.init(chartDom);
    let option;
    let num = props.data.unlock,
      title = '已解锁题目';

    option = {
      // 标题
      title: [
        // 标题
        {
          text: title,
          left: 'center',
          top: innerWidth > 1600 ? '35%' : '32%',
          textStyle: {
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: innerWidth > 1600 ? 12 : 9,
            fontWeight: 'bold',
          },
        },
        // 数量
        {
          text: '\n{num|' + num + '}', // 使用富文本标记，将数字部分用 {num} 标记
          left: 'center',
          top: innerWidth > 1600 ? '32%' : '25%',
          textStyle: {
            rich: {
              num: {
                color: '#366EF4',
                fontSize: innerWidth > 1600 ? 24 : 18,
                fontWeight: 'bold',
              },
            },
          },
        },
      ],
      // 数据项
      series: [
        {
          name: 'Access From',
          type: 'pie',
          width: '130%',
          height: '130%',
          left: '-15%',
          top: '-15%',
          radius: ['45%', '70%'],
          avoidLabelOverlap: false,
          label: { show: false },
          emphasis: { disabled: true },
          labelLine: { show: false },
          data: props.data.statistic,
          color: ['#FA9550', '#F6685D', '#56C08D'],
          itemStyle: { borderColor: '#fff', borderWidth: 1 },
        },
      ],
    };

    chartDom && option && pieChart.setOption(option);
    // 监听仪表盘的渲染进度
    pieChart.on('finished', (params) => {
      emits('onFinished', 'pie');
    });
  },
  resize = () => {
    pieChart?.resize();
  };

// defineExpose({ initPieChart });

watchEffect(() => {
  if (props.status) initPieChart();
});
</script>

<style lang="less" scoped>
.pie_chart {
  width: 100%;
  height: 100%;
}
</style>

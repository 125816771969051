<!--
 * @Date: 2023-05-16 16:35:54
 * @LastEditTime: 2024-05-31 11:51:46
-->
<template>
  <div class="courseClassify" ref="classifyRef">
    <a-modal
      v-model:visible="visible"
      :closable="false"
      :maskClosable="false"
      :destroyOnClose="true"
      :footer="null"
      :getContainer="() => $refs.classifyRef"
      placement="left"
    >
      <div class="modal_top">
        <p class="title">课程科目</p>
        <img
          src="@/assets/header/closeBlack.png"
          class="close"
          @click="onClose"
        />
      </div>

      <div class="tabs">
        <div class="tabs_nav">
          <div
            v-for="tab in tabs"
            :class="['tabs_tab', { tabs_tab_active: tab.ccId === activeTabId }]"
            :key="tab.ccId"
            @click="getCourses(tab)"
          >
            <div class="pic_box">
              <img :src="tab.ccIcon" alt="picture" class="pic" />
            </div>
            <div class="right_box">
              <p class="name">{{ tab.ccName }}</p>
            </div>
          </div>
        </div>
        <div class="tabs_content">
          <a-space direction="vertical">
            <a-tag
              :class="[
                'tag',
                { tag_active: activeCourse.courseId == course.courseId },
              ]"
              v-for="course in courses"
              :key="course"
              @click="clickCourse(course)"
              >{{ course.courseName }}</a-tag
            >
          </a-space>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  getCurrentInstance,
  onMounted,
  watch,
  watchEffect,
} from 'vue';
import { useCommonStore, useUserStore } from '@/store';

const { proxy } = getCurrentInstance(),
  commonStore = useCommonStore(),
  userStore = useUserStore();

const emits = defineEmits(['close', 'setCourse']);
// 获取课程列表数据
const getCourseClassList = () => {
    proxy.$api
      .getCourseClassList()
      .then((res) => {
        if (res.data) {
          tabs.value = res.data;
          getShowCourses(activeTabId.value);
        }
      })
      .finally(() => {
        getActiveCourse();
      });
  },
  //  获取当前选中课类的可成
  getShowCourses = (ccId) => {
    courses.value =
      tabs.value.find((tab) => tab.ccId == ccId)?.courseInfoVoList ?? [];
  },
  // 获取当前选中的课程
  getActiveCourse = () => {
    // 默认为用户选中的课程否则为当前已选中的课程
    activeCourse.value =
      courses.value?.find(
        (course) => course.courseId == activeCourseId.value
      ) ?? activeCourse.value;

    emits('setCourse', activeCourse.value);
  };

const visible = ref(false),
  open = () => {
    visible.value = true;
  },
  onClose = () => {
    emits('close');
    visible.value = false;
  };
defineExpose({ open });

// 下拉框
const tabs = ref([]),
  activeTabId = ref(-1),
  getCourses = (tab) => {
    activeTabId.value = tab.ccId;
    getShowCourses(activeTabId.value);
  },
  courses = ref([]),
  activeCourseId = ref(-1),
  activeCourse = ref({}),
  clickCourse = (course) => {
    activeCourse.value = course;
    const { ccId, courseId, isUserBuy } = activeCourse.value;
    let path = '/classification/content';

    if (!isUserBuy) path = '/courses/intro';
    proxy.$router.push({ query: { ccId, courseId }, path });
    emits('setCourse', activeCourse.value);
    emits('close');
    onClose();
  };

const mountedAction = () => {
  const { courseId, ccId } = proxy.$route.query;
  activeTabId.value = Number(ccId);
  activeCourseId.value = Number(courseId);
  getCourseClassList();
};

watchEffect(() => {
  if (['content', 'intro'].includes(proxy.$route.name)) mountedAction();
});
</script>

<style lang="less" scoped>
:deep(.ant-modal-mask) {
  opacity: 0 !important;
}

:deep(.ant-modal) {
  width: 791px !important;
  height: 492px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 60px;
  left: 199px;
  margin: 0;
  padding: 24px;

  .ant-modal-content {
    width: 100%;
    height: 100%;
    box-shadow: unset;

    .ant-modal-body {
      width: 100%;
      height: 100%;
      padding: 0;

      .modal_top {
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title {
          height: 28px;
          font-size: 20px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.9);
          line-height: 28px;
        }

        .close {
          width: 24px;
          height: 24px;
        }
      }

      .tabs {
        width: 100%;
        height: 400px;
        display: flex;
        margin-top: 16px;

        .tabs_nav {
          width: 190px;
          height: 100%;
          box-shadow: inset -1px 0px 0px 0px #e7e7e7;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 0;
          }

          .tabs_tab {
            width: 100%;
            height: 80px;
            padding: 16px;
            box-sizing: border-box;
            display: flex;
            cursor: pointer;
            border: 1px solid transparent;

            .pic_box {
              width: 48px;
              height: 48px;
              background: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 8px;

              .pic {
                width: 31px;
                height: 32px;
              }
            }

            .right_box {
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin-left: 14px;

              .name {
                height: 28px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 600;
                font-size: 20px;
                color: #3d3d3d;
                line-height: 28px;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
              }
            }

            &:hover {
              background: #e3f9e9;
              border: 1px solid rgba(61, 61, 61, 0.1);

              .right_box {
                .name {
                  color: #008858;
                }
              }
            }

            &.tabs_tab_active {
              background: #2ba471;
              border: 1px solid rgba(61, 61, 61, 0.1);

              .right_box {
                .name {
                  color: rgba(255, 255, 255, 0.9);
                }
              }
            }
          }
        }

        .tabs_content {
          width: 0;
          flex: 1;
          height: 100%;
          padding: 0 24px;
          box-sizing: border-box;

          .ant-space-vertical {
            flex-direction: row;
            flex-wrap: wrap;

            .tag {
              height: 40px;
              padding: 8px 24px;
              box-sizing: border-box;
              border-radius: 40px;
              font-size: 16px;
              border: 1px solid rgba(0, 0, 0, 0.4);
              color: rgba(0, 0, 0, 0.9);
              margin-right: 0;
              display: flex;
              align-items: center;
              cursor: pointer;

              &:hover {
                background: #c6f3d7;
                border: 1px solid #008858;
                color: #008858;
              }

              &.tag_active {
                border: unset;
                background: #2ba471;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="radar_box" ref="radarChartRef"></div>
</template>

<script setup>
import { useCommonStore } from '@/store';
import { getColor } from '@/utils';
import {
  ref,
  reactive,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  computed,
  watchEffect,
} from 'vue';
import * as echarts from 'echarts';

const { proxy } = getCurrentInstance(),
  commonStore = useCommonStore();

onMounted(() => {
  // 监听窗口大小变化
  window.addEventListener('resize', resize);
});

onUnmounted(() => {
  // 清除监听
  window.removeEventListener('resize', resize);
});

const emits = defineEmits(['onFinished']),
  props = defineProps({
    // 展示的数据内容
    dataSource: { type: Object },
    // 指示器
    indicator: { type: Object },
    // 数据状态（获取后端数据是否完成）
    status: { type: Boolean, default: false },
  });

let radarChart,
  bgColor = ['#366EF4', '#2BA471', '#D54941', '#B07AFF', '#F8BA0A', '#E37318'],
  radarChartRef = ref(null),
  formatter = (value) => {
    // 与后端约定的数据格式，_前是数据值，_后是展示顺序
    const arr = value?.split('_');
    if (arr && arr?.length) {
      const val = arr[0];
      const index = arr[1];
      return { val, index };
    } else return {};
  },
  // 初始化雷达图
  initRadarChart = () => {
    let chartDom = radarChartRef.value,
      option,
      myData = [];
    // 雷达图配置
    radarChart = chartDom && echarts.init(chartDom);

    if (props?.dataSource.length > 0)
      props?.dataSource.forEach((item) => {
        myData.push(item.score);
      });
    // 雷达图配置项
    option = {
      radar: {
        indicator: props.indicator,
        radius: '70%',
        axisLine: { lineStyle: { color: '#E7E7E7' } },
        axisName: { fontSize: window.innerWidth > 1280 ? 14 : 8 },
      },
      // 数据项
      series: [
        {
          type: 'radar',
          data: [
            {
              value: myData,
              symbol: `image://${require('@/assets/personalCenter/radar_indicator.png')}`,
            },
          ],
          itemStyle: {
            normal: {
              lineStyle: { color: '#2BA471' },
              areaStyle: { color: '#E3F9E9' },
            },
          },
        },
      ],
    };

    chartDom && option && radarChart.setOption(option);
    // 监听仪表盘的渲染进度
    radarChart.on('finished', (params) => {
      emits('onFinished', 'radar');
    });
  },
  resize = () => {
    radarChart?.resize();
  };

// defineExpose({ initRadarChart });

watchEffect(() => {
  if (props.status) initRadarChart();
});
</script>

<style lang="less" scoped>
.gauge_chart {
  width: 100%;
  height: 100%;
}
</style>

<!--
 * @Author: gyh
 * @Date: 2024-06-03 10:01:15
 * @LastEditTime: 2024-06-03 11:22:17
-->
<template>
  <div class="item">
    <img :src="cardItem.cover" alt="" class="bg" @click="clickBg(cardItem)" />
    <div class="msg_box">
      <div class="tags">
        <div class="tag" v-if="cardItem.tagList?.length == 0">暂无标签</div>
        <div class="tag" v-else v-for="tag in cardItem.tagList" :key="tag">
          {{ tag }}
        </div>
      </div>
      <div class="bottom_box">
        <template v-if="cardItem.type == 2">
          <template v-if="cardItem.isUserBuy">
            <template v-if="cardItem.packType == 1">
              <div class="btn_box">
                <a-button class="m_btn3" @click="toTrial(cardItem)">
                  进入练习模式</a-button
                >
                <a-button class="m_btn1" @click="toExam(cardItem)">
                  进入测试</a-button
                >
                <a-button
                  :disabled="!cardItem.latestPaperId"
                  class="m_btn4"
                  @click="
                    $router.push({
                      name: 'summary',
                      query: { paperId: cardItem.latestPaperId },
                    })
                  "
                >
                  测试总结</a-button
                >
              </div>
            </template>
            <template v-if="cardItem.packType == 0">
              <p class="text1">刷题进度：{{ cardItem.studySchedule }}</p>
              <a-button class="m_btn1" @click="toExercise(cardItem)"
                >{{ cardItem.state == 0 ? '开始' : '继续' }}刷题</a-button
              >
            </template>
          </template>
          <template v-else>
            <div class="left">
              <!-- <p class="text2">￥{{ cardItem.sellingPrice }}</p>
                    <p class="text3">￥{{ cardItem.originalPrice }}</p> -->
            </div>

            <a-button
              v-if="VUE_APP_GAME == 0"
              class="m_btn2"
              @click="go2buy(cardItem)"
            >
              <img src="@/assets/classification/buy.svg" class="buy_icon" />
              详情咨询</a-button
            >
          </template>
        </template>
        <template v-if="cardItem.type == 1">
          <template v-if="cardItem.isUserBuy">
            <p class="text1_c">学习进度：{{ cardItem.studySchedule }}</p>
            <a-button class="m_btn3" @click="toContent(cardItem)"
              >{{ cardItem.state == 0 ? '开始' : '继续' }}学习</a-button
            >
          </template>
          <template v-else>
            <div class="left">
              <!-- <p class="text2">￥{{ cardItem.sellingPrice }}</p>
              <p class="text3">￥{{ cardItem.originalPrice }}</p> -->
            </div>

            <a-button
              v-if="VUE_APP_GAME == 0"
              class="m_btn2"
              @click="go2buy(cardItem)"
            >
              <img src="@/assets/classification/buy.svg" class="buy_icon" />
              详情咨询</a-button
            >
          </template>
        </template>
      </div>
    </div>
    <div class="text_box">
      <p class="text1">{{ cardItem.name }}</p>
      <p class="text2">{{ cardItem.subtitle }}</p>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance } from 'vue';
import { go2buy } from '@/utils';
import { get, set } from '@vueuse/core';
import { useCommonStore, useUserStore } from '@/store';

const { proxy } = getCurrentInstance(),
  { VUE_APP_GAME } = process.env,
  userStore = useUserStore(),
  commonStore = useCommonStore(),
  props = defineProps({ cardItem: Object });

const clickBg = (contest) => {
    if (contest.isUserBuy) {
      if (contest.type == 2) {
        if (contest.packType == 1) return;
        toExercise(contest);
      } else {
        toContent(contest);
      }
    } else {
      if (process.env.VUE_APP_GAME != 0) return;
      go2buy(contest);
    }
  },
  toContent = async (contest) => {
    if (contest.sellingPrice == 0) {
      const res = await proxy.$api.bindFreeCourse({
        courseId: contest.id,
        stuId: userStore?.userInfo?.stuId,
      });
      if (res) {
        proxy.$router.push({
          name: 'content',
          query: {
            courseId: contest.id,
            ccId: contest.ccId,
          },
        });
      }
    } else {
      proxy.$router.push({
        name: 'content',
        query: {
          courseId: contest.id,
          ccId: contest.ccId,
        },
      });
    }
  },
  toTrial = async (contest) => {
    if (contest.sellingPrice == 0) {
      const res = await proxy.$api.bindFreePack({
        packId: contest.id,
        stuId: userStore?.userInfo?.stuId,
      });
      if (res) {
        proxy.$router.push({
          name: 'trials',
          query: { paperId: contest.practicePaperId },
        });
      }
    } else {
      proxy.$router.push({
        name: 'trials',
        query: { paperId: contest.practicePaperId },
      });
    }
  },
  toExam = async (contest) => {
    if (contest.sellingPrice == 0) {
      const res = await proxy.$api.bindFreePack({
        packId: contest.id,
        stuId: userStore?.userInfo?.stuId,
      });
      if (res) {
        const params = {
          packId: contest.id,
          stuId: userStore?.userInfo?.stuId,
        };
        let res = await proxy.$api.generateTrialsPaper(params);
        if (res) {
          proxy.$router.push({
            name: 'exam',
            query: { paperId: res.data },
          });
        }
      }
    } else {
      const params = {
        packId: contest.id,
        stuId: userStore?.userInfo?.stuId,
      };
      let res = await proxy.$api.generateTrialsPaper(params);
      if (res) {
        proxy.$router.push({
          name: 'exam',
          query: { paperId: res.data },
        });
      }
    }
  },
  toExercise = async (contest) => {
    if (contest.sellingPrice == 0) {
      const res = await proxy.$api.bindFreePack({
        packId: contest.id,
        stuId: userStore?.userInfo?.stuId,
      });
      if (res) {
        proxy.$router.push({
          name: 'exerciseList',
          query: {
            packId: contest.id,
          },
        });
      }
    } else {
      proxy.$router.push({
        name: 'exerciseList',
        query: {
          packId: contest.id,
        },
      });
    }
  };
</script>

<style lang="less" scoped>
.item {
  width: 100%;
  position: relative;
  .bg {
    width: 359px;
    height: 202px;
    border-radius: 16px 16px 0px 0px;
    cursor: pointer;
  }
  .text_box {
    padding: 24px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    .text1,
    .text2 {
      font-weight: 600;
      font-size: 20px;
      color: rgba(255, 255, 255, 0.9);
      line-height: 28px;
      text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
      text-stroke: 2px rgba(0, 0, 0, 0.15);
      font-style: normal;
      text-transform: none;
      -webkit-text-stroke: 2px rgba(0, 0, 0, 0.15);
    }
    .text1 {
      margin-bottom: 16px;
    }
  }
  .msg_box {
    // min-height: 113px;
    background: #ffffff;
    border-radius: 0px 0px 16px 16px;
    padding: 16px;
    box-sizing: border-box;
    .tags {
      margin: 0 8px 12px 0;
      display: flex;
      .tag {
        margin-right: 4px;
        padding: 4px 8px;
        background: #fff1e9;
        border-radius: 9999px 9999px 9999px 9999px;
        font-weight: 600;
        font-size: 12px;
        color: #e37318;
        line-height: 20px;
      }
    }
    .bottom_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .text1 {
        font-weight: 600;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
        line-height: 22px;
      }
      .text1_c {
        font-weight: 600;
        font-size: 13px;
        color: #e37318;
        line-height: 22px;
      }
      .text2 {
        font-weight: 600;
        font-size: 24px;
        color: #e34d59;
        line-height: 32px;
      }

      .text3 {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.4);
        line-height: 24px;
        text-decoration-line: line-through;
      }

      .left {
        display: flex;
        align-items: end;
      }

      .m_btn1 {
        border: unset;
        background: #2ba471;
        border-radius: 16px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.9);
        line-height: 22px;
        &:hover {
          box-shadow: 0px 4px 10px 0px rgba(43, 164, 113, 0.85);
        }
      }
      .m_btn2 {
        width: fit-content;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        color: #d54941;
        background: unset;
        box-shadow: unset;
        border: unset;
        .buy_icon {
          width: 28px;
          height: 28px;
          margin-right: 8px;
        }

        &:hover {
          background: linear-gradient(270deg, #d54941 0%, #f6685d 100%);
          box-shadow: 0 4px 10px 0 rgba(246, 104, 93, 0.85);
          border-radius: 16px 16px 16px 16px;
          border: 1px solid #d54941;
          color: rgba(255, 255, 255, 0.9);
          font-size: 14px;

          .buy_icon {
            width: 16px;
            height: 16px;
            content: url('@/assets/classification/buy_hover.svg');
          }
        }
      }
      .m_btn3 {
        border: unset;
        background: #e37318;
        border-radius: 16px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.9);
        line-height: 22px;
        &:hover {
          box-shadow: 0px 4px 10px 0px rgba(250, 149, 80, 0.85);
        }
      }
      .m_btn4 {
        border: unset;
        background: #e3f9e9;
        border-radius: 16px;
        font-size: 14px;
        color: #008858;
        line-height: 22px;
        border: 1px solid #2ba471;
        &:hover {
          box-shadow: 0px 4px 10px 0px rgba(43, 164, 113, 0.85);
        }
      }
      .btn_box {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .m_btn4,
        .m_btn1 {
          padding: 5px 21px;
        }
      }
    }
  }
}
</style>

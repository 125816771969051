/*
 * @Date: 2023-02-14 09:56:14
 * @LastEditTime: 2024-05-31 17:02:09
 */
import PageLayout from '@/layout/PageLayout';
import BasicLayout from '@/layout/BasicLayout';

export default [
  {
    path: '/user',
    name: 'user',
    component: PageLayout,
    redirect: '/user/login',
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/user/login'),
      },
    ],
  },
  {
    path: '/user',
    component: BasicLayout,
    children: [
      {
        path: 'codeSetDetails',
        name: 'codeSetDetails',
        component: () =>
          import('@/views/studyCenter/studyRecord/codeSet/details'),
      },
    ],
  },
];

<template>
  <div class="left_nav">
    <div class="left_nav_title">
      <slot name="title"></slot>
    </div>

    <div class="left_nav_courses">
      <div
        v-for="item in items"
        :class="['course', { course_active: activeCourseId == item.ccId }]"
        :key="item.ccId"
        @click="clickAnchor(item)"
      >
        <div class="course_active_box" v-if="activeCourseId == item.ccId"></div>

        <img class="course_icon" :src="item.ccIcon" />
        <div class="course_desc">
          <span class="course_name">{{ item.ccName }}</span>
          <span class="course_age">
            <template v-if="type == 'myCourse'"
              >在学课程 {{ item.learning || 0 }} 已学完
              {{ item.learned || 0 }}</template
            >
            <template v-else>
              适合年龄：{{ item.ageGroup || '暂无' }}
            </template>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance } from 'vue';

const { proxy } = getCurrentInstance();

const props = defineProps({
  items: Array,
  type: String,
});

const emits = defineEmits(['clickItem']);

const activeCourseId = ref(1),
  clickAnchor = (item) => {
    activeCourseId.value = item.ccId;
    emits('clickItem', item);
  };

defineExpose({ clickAnchor });
</script>

<style lang="less" scoped>
.left_nav {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.45);
  box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.55);
  z-index: 3;
  padding: 24px 0 0 0;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;

  .left_nav_title {
    width: calc(100% - 32px);
    height: 44px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 8px 0 8px 15px;
    box-sizing: border-box;
    margin-left: 16px;
    display: flex;
    align-items: center;
  }

  .left_nav_courses {
    width: 100%;
    height: fit-content;
    margin-top: 24px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
    }

    .course {
      width: 100%;
      height: 104px;
      cursor: pointer;
      padding: 16px 16px 16px 32px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      position: relative;

      &::after {
        width: calc(100% - 16px);
        height: 1px;
        content: '';
        background: rgba(0, 0, 0, 0.1);
        position: absolute;
        bottom: 0;
        left: 16px;
      }

      .course_active_box {
        width: 16px;
        height: 100%;
        background: #008858;
        position: absolute;
        right: 0;
      }

      .course_icon {
        width: 72px;
        height: 72px;
        background: #ffffff;
        border-radius: 16px 16px 16px 16px;
        border: 1px solid #dcdcdc;
        padding: 6px;
        box-sizing: border-box;
      }

      .course_desc {
        width: 0;
        flex: 1;
        padding-left: 16px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .course_name {
          height: 36px;
          font-size: 28px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.9);
          line-height: 36px;
        }

        .course_age {
          height: 24px;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.6);
          line-height: 24px;
          margin-top: 4px;
        }
      }

      &:hover {
        background: #fff;

        &::after {
          display: none;
        }

        .course_icon {
          background: #c6f3d7;
          border: 1px solid #008858;
        }

        .course_desc {
          .course_name {
            color: #008858;
          }

          .course_age {
            color: #2ba471;
          }
        }
      }

      &.course_active {
        width: calc(100% - 16px);
        background: #2ba471;
        border-radius: 16px 0 0 16px;
        padding-left: 16px;
        margin-left: 16px;

        .course_icon {
          background: #fff;
          border: 1px solid #008858;
        }

        .course_desc {
          .course_name {
            color: rgba(255, 255, 255, 0.9);
          }

          .course_age {
            color: rgba(255, 255, 255, 0.9);
          }
        }
      }
    }
  }
}
</style>

<!--
 * @Date: 2023-02-14 09:56:13
 * @LastEditTime: 2023-06-12 10:15:40
-->
<template>
  <template v-if="isShowView && isRouterActive">
    <a-config-provider :locale="locale">
      <router-view :key="route.fullPath" />
    </a-config-provider>
  </template>
  <errorTips v-else />
</template>

<script setup>
import { useRoute } from "vue-router";
import {
  getCurrentInstance,
  onMounted,
  watchEffect,
  computed,
  ref,
  onBeforeMount,
  provide,
  nextTick
} from "vue";
import { useSseStore } from "./store";
import { browserRealease } from "@/utils/browserUtils";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import errorTips from "@/views/exception/errorTips.vue";

dayjs.locale("zh-cn");
const isRouterActive = ref(true);
provide("reload", () => {
  isRouterActive.value = false;
  nextTick(() => {
    isRouterActive.value = true;
  });
});
const { proxy } = getCurrentInstance(),
  sseStore = useSseStore(),
  locale = zhCN,
  route = useRoute();

const isShowView = computed(() => {
  let flag = true;
  const { isPc, isAllowedUse, isNeededUpdate, win } = browserRealease();
  if (!["category", "database", "intro"].includes(route.name)) {
    flag =
      isPc && isAllowedUse && !isNeededUpdate && !win?.isXp && !win?.isWin7;
  }
  return flag;
});

onMounted(() => {
  getConnectId();
});

const getConnectId = () => {
  proxy.$api.getConnectId().then((res) => {
    if (res.resultCode == 200) sseStore.s_set_connect_id(res.data);
  });
};
</script>
<style lang="less" scoped>
.render_box {
  width: 100vw;
  height: 100vh;
  background: url("@/assets/bg.png");
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 80px;

  .illustration {
    width: 880px;
    height: 680px;
    position: absolute;
    top: 160px;
    left: 140px;
  }
}
</style>

/*
 * @Date: 2023-02-14 09:56:14
 * @LastEditTime: 2024-05-27 10:58:46
 */
import axios from 'axios';
import { useUserStore } from '@/store/index';
import { message } from 'ant-design-vue';
import router from '@/router';

const request = axios.create({
  baseURL: '/api',
  timeout: 60000,
  headers: {},
});

// 异常拦截处理器
const errorHandler = (error) => {
  return Promise.reject(error);
};

request.interceptors.request.use((config) => {
  const counter = useUserStore();
  if (counter.token) config.headers.Authorization = counter.token;
  return config;
}, errorHandler);

request.interceptors.response.use((response) => {
  // console.log(response);
  // if (response.config.url.indexOf('downloadStudentWork')) return response.data;
  const { success, resultCode, resultMsg, data } = response.data;
  if (success || resultCode == 600) return { data, resultMsg, resultCode };
  else {
    switch (resultCode) {
      case 401:
        message.warning('登录过期，请重新登录。');
        break;
      case 400:
        if (data && data?.code == 'TOKEN_NOT_VALID') {
          message.warning(data.errorMessage);
          return useUserStore().clearCacheAndSkip();
        } else if (response.config.url.indexOf('getWxInfo'))
          return { data, resultCode };
        else message.warning(resultMsg, 2);
        return { data, resultCode };
        break;
      case 301:
      case 500:
        message.warning(resultMsg, 2);
        break;
      case 2007:
        message.warning(data.errorMessage);
        useUserStore().clearCacheAndSkip();
        break;
      default:
        message.warning(resultMsg, 2);
        return { data, resultCode };
        break;
    }
  }
}, errorHandler);

export { request };

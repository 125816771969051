import { request } from "@/request/request";
import { parameterChange } from "@/utils";

export default {
  // 根据年份获取我的胶囊
  getMyCapsuleByYear(data) {
    return request({
      url: "/user/time-capsule/getMyCapsuleByYear?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 根据月份获取我的胶囊
  getMyCapsuleByMonth(data) {
    return request({
      url: "/user/time-capsule/getMyCapsuleByMonth?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 胶囊详情
  getMyCapsuleById(data) {
    return request({
      url: "/user/time-capsule/getMyCapsuleById?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 发射时光胶囊
  launchCapsule(data) {
    return request({
      url: "/user/time-capsule/launchCapsule",
      method: "post",
      data,
    });
  },
  // 获取当年的胶囊数量
  getCapsuleCount(data) {
    return request({
      url: "/user/time-capsule/getCapsuleCount",
      method: "get",
      data,
    });
  },
};

<!--
 * @Date: 2023-04-19 16:15:34
 * @LastEditTime: 2024-06-05 11:23:42
-->
<template>
  <div class="header">
    <div class="left_box">
      <!-- 华园科创图标S -->
      <img
        src="@/assets/header/logoText.png"
        alt="logo_text"
        class="logo_text"
        @click="go2page(2, 'category')"
      />
      <!-- 图标F -->
      <!-- 机构图标S -->
      <template v-if="companyLogo">
        <!-- <span class="mix">×</span> -->
        <img
          :src="companyLogo"
          alt="companyLogo"
          class="company_logo"
          @click="go2page(2, 'category')"
        />
      </template>
      <!-- 机构图标F -->
      <!-- 返回上一页S -->
      <a-button
        class="back_btn"
        v-if="isShowBack && !isShowSelector"
        @click="go2page(0)"
        ><LeftOutlined />返回上一页</a-button
      >
      <slot name="custom"></slot>

      <!-- 面包屑S -->
      <!-- <Breadcrumb
        v-if="isShowBreadcrumb"
        itemColor="rgba(255,255,255,0.55)"
        lastItemColor="rgba(255,255,255,0.9)"
      /> -->
      <!-- 面包屑F -->

      <!-- 课程下拉弹窗S -->
      <div
        v-if="isShowSelector"
        :class="[
          'selector',
          { selector_active: isOpened },
          { selector_bg: VUE_APP_GAME == 0 },
        ]"
        @click="handleClickSelector"
      >
        <p class="label">{{ course.courseName }}</p>
        <img :class="['selector_arrow', { selector_arrow_active: isOpened }]" />
      </div>
      <!-- 课程下拉组件 -->
      <CourseClassify
        ref="courseClassifyRef"
        :course="course"
        @close="isOpened = false"
        @setCourse="(e) => (course = e)"
      />
      <!-- 课程下拉弹窗F -->
    </div>

    <!-- tabs -->
    <div class="entrance_tabs" v-if="VUE_APP_GAME == 0">
      <template v-for="(tab, index) in tabs" :key="tab.name">
        <div
          :class="[
            'btn_box',
            { active_tab_box: activeTabName.includes(tab.name) },
          ]"
        >
          <div class="btn" @click="clickTab(tab.name)">
            <img
              :src="
                activeTabName.includes(tab.name) ? tab.activeIcon : tab.icon
              "
              alt="online"
              class="icon"
            />
            <span class="label">{{ tab.label }}</span>
          </div>
          <div
            class="divider"
            :style="{
              opacity:
                index != tabs.length - 1 &&
                index != activeTabIndex - 1 &&
                index != activeTabIndex
                  ? 1
                  : 0,
            }"
          ></div>
        </div>
      </template>
    </div>
    <!-- 在线课程、练习题库tabF -->

    <div class="right_box">
      <!-- 我的课程S -->
      <div class="item" @click="go2page(1)" v-if="VUE_APP_GAME == 0">
        <img src="@/assets/header/website.svg" class="item_icon" />
        <span class="item_label">进入官网</span>
      </div>
      <template v-if="userStore.token">
        <div class="item" @click="go2page(3, 'personalInfo')">
          <img src="@/assets/header/userCenter.svg" class="item_icon" />
          <span class="item_label">个人中心</span>
        </div>
        <!-- 个人信息S -->
        <HeaderDropdown />
        <!-- 个人信息F -->
      </template>
      <!-- 登录、注册按钮S -->
      <template v-else>
        <a-space>
          <a-button class="btn login_btn" ghost @click="go2login(0)">
            <span>登录</span>
          </a-button>
          <a-button class="btn register_btn" @click="go2login(1)">
            <span>注册</span>
          </a-button>
        </a-space>
      </template>
      <!-- 登录、注册按钮F -->
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  getCurrentInstance,
  watchEffect,
  computed,
  reactive,
  onMounted,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { CourseClassify, HeaderDropdown, Breadcrumb } from '@/components';
import { useCommonStore, useUserStore } from '@/store';
import { LeftOutlined } from '@ant-design/icons-vue';

const { proxy } = getCurrentInstance(),
  route = useRoute(),
  router = useRouter(),
  userStore = useUserStore(),
  commonStore = useCommonStore(),
  { VUE_APP_GAME } = process.env;

onMounted(() => {
  userStore.token && userStore.getStuInfo();
});

/**
 * 在线课程/练习题库tab
 */
const tabs = reactive([
    {
      name: 'category',
      label: '平台首页',
      icon: require('@/assets/header/tabs/home.svg'),
      activeIcon: require('@/assets/header/tabs/homeActive.svg'),
    },
    {
      name: 'studyCenter',
      label: '学习中心',
      icon: require('@/assets/header/tabs/studyCenter.svg'),
      activeIcon: require('@/assets/header/tabs/studyCenterActive.svg'),
    },
    {
      name: 'exercise',
      label: '能力提升',
      icon: require('@/assets/header/tabs/brushExercise.svg'),
      activeIcon: require('@/assets/header/tabs/brushExerciseActive.svg'),
    },
    {
      name: 'academicAssessment',
      label: '学情评估',
      icon: require('@/assets/header/tabs/academicAssessment.svg'),
      activeIcon: require('@/assets/header/tabs/academicAssessmentActive.svg'),
    },
    {
      name: 'learningFiles',
      label: '学习档案',
      icon: require('@/assets/header/tabs/learningFiles.svg'),
      activeIcon: require('@/assets/header/tabs/learningFilesActive.svg'),
    },
  ]),
  activeTabName = ref(),
  activeTabIndex = computed(() => {
    return tabs.findIndex((tab) => activeTabName.value.includes(tab.name));
  }),
  clickTab = (name) => {
    activeTabName.value = [name];
    go2page(3, name);
  };
/**
 * 在线课程/练习题库tab
 */

/**
 * 机构logo
 */
const isSpecialLogo = ref(false),
  companyLogo = computed(() => {
    const { companyInfos } = userStore.userInfo ?? {};
    const { companyLogo } = commonStore;

    let logo = companyInfos?.length == 1 ? companyInfos[0]?.companyLogo : null;
    if (isSpecialLogo.value) logo = companyLogo;

    return logo;
  });
/**
 * 机构logo
 */

const routerName = ref('');
watchEffect(() => {
  const { meta, name } = route;

  routerName.value = [name, meta.pName];
  activeTabName.value = [name, meta.pName];

  // 单元课时、学习课次展示独特的logo
  isSpecialLogo.value = ['content', 'learn', 'intro'].includes(name);
});

const // 面包屑已注释
  // isShowBreadcrumb = computed(() => {
  //   return route.meta.breadcrumb;
  // }),
  // 课程下拉
  isShowSelector = computed(() => {
    return (
      (routerName.value.includes('content') ||
        routerName.value.includes('intro')) &&
      Object.keys(course.value).length
    );
  }),
  // isShowTrainingCenter = computed(() => {
  //   return ['content', 'learn'].includes(routerName.value);
  // }),
  // isShowMyCourseBtn = computed(() => {
  //   return !router.meta.isHideMyCourseBtn;
  // }),
  // 返回上一页
  isShowBack = computed(() => {
    // 如果没有上一级页面则直接不展示
    if (!router.options.history.state.back) return false;
    // 常规学习下只有练习列表、详情、总结、能力提升的课次列表有
    if (process.env.VUE_APP_GAME == 0)
      return ['exerciseDetail', 'summary', 'exerciseList', 'content'].includes(
        routerName.value[0]
      );
    else return !['game'].includes(routerName.value[0]);
  });

/**
 * 课程类别弹窗
 */
const isOpened = ref(false),
  course = ref({}),
  handleClickSelector = () => {
    if (VUE_APP_GAME != 0) return;
    isOpened.value = true;
    proxy.$refs.courseClassifyRef.open();
  };
/**
 * 课程类别弹窗
 */

// 登录/注册 isRegister：是否注册
const go2login = (isRegister) => {
  isRegister && commonStore.s_set_is_register(true);
  go2page(3, 'login');
};

const go2page = (num, name) => {
  switch (num) {
    case 0: // 返回上一页
      proxy.$router.go(-1);
      break;
    case 1: // 官网
      const url = 'https://www.codedancing.cn/#/';
      window.open(url, '_blank');
      break;
    case 2: // 平台首页
      VUE_APP_GAME == 0 && proxy.$router.push({ name });
      break;
    default:
      // 个人中心、登录/注册、点击tab
      proxy.$router.push({ name });
      break;
  }
};
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 64px;
  background: #00a870;
  padding: 0 24px 0 27px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .left_box {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    .logo_text {
      width: 160px;
      height: 100%;
    }

    .mix {
      width: fit-content;
      height: fit-content;
      padding: 0 20px 0 10px;
      box-sizing: border-box;
      color: #ffffff;
      font-size: 16px;
      text-align: center;
      line-height: 30px;
    }

    .company_logo {
      // width: 160px;
      max-height: 100%;
      object-fit: contain;
    }

    .logo {
      width: 64px;
      height: 100%;
    }

    .selector {
      height: 32px;
      border-radius: 3px;
      padding: 5px 16px;
      display: flex;
      align-items: center;
      margin-left: 24px;

      .label {
        font-size: 16px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.9);
      }

      .selector_arrow {
        width: 16px;
        height: 16px;
        margin-left: 12px;
        content: url('@/assets/header/arrowDownWhite.svg');
      }

      &.selector_active {
        background: #c6f3d7;
        border: 1px solid #008858;

        .label {
          color: #008858;
        }

        .selector_arrow {
          content: url('@/assets/header/arrowUpGreen.svg');
        }
      }
    }
    .selector_bg {
      cursor: pointer;
      background: #2ba471;
      border: 1px solid rgba(255, 255, 255, 0.55);
    }
  }

  .entrance_tabs {
    width: fit-content;
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);

    .btn_box {
      width: fit-content;
      height: 100%;
      padding: 14px 16px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 14px 16px;
      box-sizing: border-box;
      border: 1px solid transparent;
      position: relative;

      .btn {
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #008858;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        padding: 7px 16px;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background: #f9f6d0;
          box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3);
          border: 1px solid #008858;
        }

        .icon {
          width: 16px;
          height: 16px;
        }

        .label {
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 14px;
          color: #008858;
          line-height: 22px;
          margin-left: 8px;
        }
      }

      &.active_tab_box {
        background: rgba(0, 0, 0, 0.26);
        border: 1px solid rgba(255, 255, 255, 0.55);

        .btn {
          background: #f9f6d0;
          border: unset;
          box-shadow: unset;

          .label {
            color: #8b6600;
          }
        }
      }

      .divider {
        width: 2px;
        height: 16px;
        background: rgba(255, 255, 255, 0.55);
        border-right: 1px solid #008f5f;
        position: absolute;
        right: 0;
      }
    }
  }

  .right_box {
    height: fit-content;
    display: flex;
    align-items: center;

    .item {
      width: fit-content;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 0 8px;

      .item_icon {
        width: 28px;
        height: 28px;
      }

      .item_label {
        height: fit-content;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 12px;
        color: #ffffff;
        line-height: 20px;
      }
    }

    .btn {
      height: fit-content;
      border-radius: 4px;
      padding: 7px 43px;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 22px;

      &.login_btn {
        color: rgba(255, 255, 255, 0.9);
        background: #008858;
        border: 1px solid rgba(255, 255, 255, 0.9);
        margin-left: 8px;
      }

      &.register_btn {
        border-color: #ffffff;
        color: #008858;
        background: #ffffff;
      }
    }
  }
  .back_btn {
    margin-left: 24px;
    width: 128px;
    height: 32px;
    background: #008858;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.9);
    font-weight: 600;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.9);
  }
}
</style>
